import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Wrapper, H } from '@farewill/ui'
import ReactMarkdown from 'react-markdown'

import DefaultLayout from 'layouts/DefaultLayout'
import { StyledRichContentWrapper } from '../components/RichContent'

const PartnerTerms = ({ data: { partnerTerms }, pageContext }) => {
  const { termsAndConditions } = partnerTerms
  return (
    <DefaultLayout
      title="Offer Terms and Conditions"
      meta={[
        {
          name: 'robots',
          content: 'noindex,follow',
        },
      ]}
    >
      <Wrapper container tag={StyledRichContentWrapper}>
        <H tag="h1" size="XL">
          Offer Terms and Conditions
        </H>
        <Wrapper tag={ReactMarkdown} escapeHtml margin={[0, 0, 'XL']}>
          {termsAndConditions.source}
        </Wrapper>
        <ReactMarkdown escapeHtml>{pageContext.terms}</ReactMarkdown>
      </Wrapper>
    </DefaultLayout>
  )
}

PartnerTerms.propTypes = {
  data: PropTypes.shape({
    partnerTerms: PropTypes.shape({
      termsAndConditions: PropTypes.shape({
        source: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    terms: PropTypes.string,
  }).isRequired,
}

export const query = graphql`
  query campaignPageBySlug($slug: String!) {
    partnerTerms: contentfulPartnerCampaignPage(slug: { eq: $slug }) {
      id
      termsAndConditions {
        source: termsAndConditions
      }
    }
  }
`

export default PartnerTerms
